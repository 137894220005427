import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

import { Provider as AlertProvider } from 'react-alert'
import { alertOptions, AlertTemplate } from './components/ReactAlert/ReactAlert'



axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.REACT_APP_DB_BASE_URL;
axios.defaults.timeout = 100000;
axios.defaults.withCredentials = true;

ReactDOM.render(
  <React.StrictMode>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <App />
      </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
