import React, { useState} from 'react';
import axios from 'axios';
import {b64encode, capitalizeFirstLetter, getDisplayedName, getDomainName} from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import './Login.css'
import { useAlert } from 'react-alert'
import { useTranslations } from "../../utils/hooks/useTranslation";


function Login() {
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const [usernameError, setUsernameError] = useState();
    const [passwordError, setPasswordError] = useState();
    const navigate = useNavigate()
    const domainName = getDomainName()
    const displayDomain = getDisplayedName()
    const alertBar = useAlert()
    const { translate } = useTranslations()

    const validateLogin = () => {
        setUsernameError(false)
        setPasswordError(false)

        if (!username) setUsernameError(true)
        if (!password) setPasswordError(true)
        return (username && password);
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (!validateLogin()) return;

        const config = {
            headers: {
                Login: b64encode(username),
                Password: b64encode(password),
            },
            data: {
                Login: b64encode(username),
                Password: b64encode(password)
            },
            url: '/auth/login',
            method: 'post'
        };

        axios(config)
            .then(response => {
                if (response.data.need_to_change_password) {
                    navigate('/forgot_password')
                }
                else if (response.data.fields && Object.values(response.data.fields).length > 0 ) {
                    navigate('/register', {state: response.data})
                }
                else if (domainName === 'auth') {
                    navigate('/profile')
                }
                else {
                    window.location.href = 'https://' + displayDomain + '.myaic.eu'
                }
            })
            .catch(error => {
                console.warn(error)
                alertBar.error(error)
            });
    };

    return (
        <div className="Login page">
            <div className="Login_container">
                <h2 className="Login_welcome_msg">
                    {translate("Log in to ")} <span className="text__medium">{displayDomain}</span></h2>
                <form className="Login_form" onSubmit={handleSubmit} noValidate>
                    <div className="aic_input">
                        <label className='aic_input_label text__medium'>{translate("Username")}</label>
                        <input
                            type="text"
                            className="aic_input_input"
                            required
                            value={username}
                            onChange={e => setusername(e.target.value)}
                            id="username"
                            autoComplete="off"
                        />
                        <div className={`aic_input_error_msg ${usernameError?'show_error':''}`}>{translate("Enter username")}</div>
                    </div>
                    <div className="aic_input">
                        <label className='aic_input_label text__medium'>
                            {translate("Password")}
                        </label>
                        <input
                            type="password"
                            className="aic_input_input"
                            required
                            value={password}
                            onChange={e => setpassword(e.target.value)}
                            id="password"
                            autoComplete="off"
                        />
                        <div className={`aic_input_error_msg ${passwordError?'show_error':''}`}>
                            {translate("Enter password")}
                        </div>
                    </div>
                    <button
                        className="btn__hard"
                        type="submit">
                        {translate("Log in")}
                    </button>
                </form>
                <div className="remember_me_and_forget_password">
                    <div className='checkbox__container'>
                        <input
                            value={password}
                            type="checkbox" />
                        <p className="checkbox__label">{translate("Remember me")}</p>
                    </div>
                    <div className="link__underline" onClick={()=> navigate('/forgot_password')}>
                        {translate("Forgot password")}
                    </div>
                </div>
                <div className="separator"/>
                <button
                    data-testid="logIn"
                    className="btn__hard_inverted"
                    onClick={()=> navigate('/register')}
                >
                    {translate("Sign up to")} {displayDomain}
                </button>
            </div>
        </div>
    );
}

export default Login;
