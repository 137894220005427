import {validateEmail} from "./validation";


export const getDomainName = () => {
    let name =  window.location.origin.replace('https://', '').replace('http://', '').split('.auth.myaic.eu')[0].split(':')[0]
    return name === 'auth.myaic.eu'?'auth': name
}

export const getDisplayedName = () => {
    const name =  window.location.origin

    if (name.includes('dev.auth.myaic.eu')) {
        return name.replace('https://', '').replace('http://', '').split('.dev.auth.myaic.eu')[0]
    } else {
        return name.replace('https://', '').replace('http://', '').split('.auth.myaic.eu')[0]
    }
}

export function b64encode(phrase) {
    return btoa(unescape(encodeURIComponent(phrase)));
}


export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}



export const validateFields = (data, turnEmailError, turnPassError) => {
    let invalidForm = false
    let pass = {}
    let newArr


    newArr = data.map(field => {
        if (field.optional|| field.disabled) return field;

        switch (field.type) {
            case 'text':
                invalidForm = invalidForm || !field.value
                return {...field, invalid: !field.value}
            case 'email':
                invalidForm = invalidForm || !field.value
                let emailValidation = !validateEmail(field.value)
                if (field.value) turnEmailError(emailValidation)
                return {...field, invalid: !field.value}
            case 'number':
                invalidForm = invalidForm || !field.value
                return {...field, invalid: !field.value}
            case 'select':
                invalidForm = invalidForm || !field.value?.value
                return {...field, invalid: !field.value?.value}
            case 'checkbox':
                invalidForm = invalidForm || !field.value
                return {...field, invalid: !field.value}
            case 'password':
                pass[field.name] = field.value;
                if (!field.value){
                    invalidForm = true
                    return {...field, invalid: !field.value}
                }
                return {...field, invalid: !field.value}
            default:
                return field;
        }
    })


    // password error
    if (Object.values(pass).length === 2){
        if (Object.values(pass)[0] !== Object.values(pass)[1]){
            turnPassError(true)
            invalidForm = true
        }
        else {
            turnPassError(false)
        }
    }

    return {'invalidForm': invalidForm, newArr: newArr }
}
